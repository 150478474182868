<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateDeptModal()">
        <PlusOutlined />新增
      </a-button>
    </div>
    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="名称">
          <a-input v-model:value="formState.name" placeholder="名称" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table rowKey="menu_id" :columns="columns" :data-source="data" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateDeptModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
    <create-dept ref="RefCreateDept" :deptList="RefDeptList" @handleSubmit="handleRefresh" />
    <update-dept ref="RefUpdateDept" :deptList="RefDeptList" @handleSubmit="handleRefresh" />
  </div>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, ref, onMounted, reactive } from "vue";
import { Modal, message } from "ant-design-vue";
import CreateDept from "./modules/CreateDept";
import UpdateDept from "./modules/UpdateDept";
import * as DeptApi from "@/views/manage/api";
const columns = [
  {
    title: "部门ID",
    dataIndex: "dept_id",
    width: "10%",
  },
  {
    title: "部门名称",
    dataIndex: "name",
    width: "12%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
    CreateDept,
    UpdateDept,
  },
  setup() {
    const formState = reactive({
      name: "",
    });
    const gradeList = ref([]);
    const data = ref([]);

    // 添加用户
    const RefCreateDept = ref();
    const showCreateDeptModal = () => {
      RefCreateDept.value.showFromModal();
    };

    // 编辑用户
    const RefUpdateDept = ref();
    const showUpdateDeptModal = (item) => {
      RefUpdateDept.value.showFromModal(item);
    };

    // 刷新列表数据
    const handleRefresh = () => {
      DeptApi.dept_list(formState).then((res) => {
        data.value = res.data.list;
      });
    };
    const RefDeptList = ref(
      DeptApi.dept_list().then((res) => {
        RefDeptList.value = res.data.list;
      })
    );

    // 删除数据
    const showDeleteConfirm = (record) => {
      console.log(record);
      Modal.confirm({
        title: "确定要删除【" + record.name + "】这个部门吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除部门后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          DeptApi.dept_del({ dept_id: record.dept_id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
    });

    return {
      gradeList,
      formState,
      columns,
      data,
      RefCreateDept,
      RefUpdateDept,
      showCreateDeptModal,
      showUpdateDeptModal,
      showDeleteConfirm,
      handleRefresh,
      RefDeptList,
    };
  },
});
</script>